/* Styles for the overall section */
.clientserved-section-er {
}

/* Bounce-in animation */
@keyframes bounceIn {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* Applying bounce-in animation to the banner text */
.clientserved-banner-text-er {
  margin: 96px;
  font-size: 96px;
  color: white; /* Adjust the color if needed */
  text-align: flex-start;
  font-family: 'Ser Black', sans-serif; /* Ensure the font is loaded correctly */
}

/* Banner section */
.clientserved-banner-er {
  display: flex;
  height: auto;
  margin-top: 109px;
  height: 400px;
  max-height: 200px;
  justify-content: flex-start;
  align-items: center;
  background-image: url('.././../../public/banner.png');
  background-size: cover;
  background-position: center;
  max-height: 423px;
}

/* Text inside the banner */
.clientserved-banner-text {
  font-size: 96px;
  color: white;
  text-align: center;
  font-family: 'Ser Black', sans-serif; /* Using Ser Black font */
}

/* Description and paragraph styles */
.clientserved-description-er {
  margin: 20px;
  margin-left: 106px;
}

.clientserved-heading-er {
  font-family: 'Roboto', ;
  font-weight: 400;
  font-size: 32px;
  color: #010243; 
  margin-bottom: 10px;
}

.clientserved-paragraph-er {
  font-size: 24px;
font-weight: 300;
font-family: 'Roboto', sans-serif; 
  color: #010243;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .clientserved-banner-er {
    margin-top: 68px;
    justify-content: center; /* Center content on smaller screens */
    background-position: top;
    max-height: 100px; /* Adjust height for mobile */
  }

  .clientserved-banner-text-er {
    margin: 20px;
    font-size: 38px; /* Reduce font size for smaller screens */
    text-align: center; /* Center text on smaller screens */
  }

  .clientserved-heading-er {
    font-size: 32px; /* Adjust heading size for mobile */
    text-align: center;
  }

  .clientserved-paragraph-er {
    margin: 30px;
    font-size: 18px; /* Adjust paragraph font size for better readability */
  }
  .clientserved-description-er {
    margin-left: 0;
  }
  
}
