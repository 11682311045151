form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  input, textarea {
    margin-bottom: 15px;
    padding: 10px;
    font-family: 'Roboto';
    font-size: 14px;
    background-color:unset;
   border: 1px solid #ffffff;
    border-radius: 50px;
  }
  
  button {
    padding: 10px;
    font-size: 16px;
    background-color:  #010243;

    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #3e41d2;
    ;
  }
  
  .contacttext p {
    text-align: start;
    font-size: 14px;
    color: black;
  }
  
  .contacttext {
    margin-bottom: 20px;
    text-align: flex-start;
  }
  
  .contacttext h1 {
    font-size: 54px;
    background: linear-gradient(91.16deg, #010243 1.74%, #51A8DB 43.71%, #51A8DB 61.62%, #010243 102.48%);
    background-clip: text;
    -webkit-background-clip: text; /* Ensures compatibility with WebKit-based browsers */
    color: transparent;
  }
  .contacttext span {
     /* Makes the gradient visible by making the text color transparent */
  }
  