
/* components/ProductCart/ProductCart.css */
.product-cart-d {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  min-height: fit-content;
  padding: 20px;
  gap: 50px;
  transition: transform .3s ease, box-shadow .3s ease;
  width: calc(80% - 20px);
}

.product-cart-d:hover {

  transform: translateY(-5px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}


.product-cart-d img {
  width: 100%; /* Makes the image responsive */
  height: auto;

}




.product-cart-d p{

  font-size: 18px;
  font-style: "Roboto";
  font-weight: 300;
  text-align: center; /* Centers the text */
  margin: 0;
}