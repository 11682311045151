.modal-overlay {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
  }
  
  .modal-content img {
    width: 600px;
    height:500px;
  }
  
  @media screen and (max-width: 1266px) {
    .modal-content {
      max-width: 90%;
    }
  }