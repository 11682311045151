body {
  margin: 0;
  padding: 0;
  background-color: #F8F8F8;
  width: 100%;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: inherit; /* Keeps the default color of the text */
}

a:hover {
  color: #007BFF; /* Optional: Highlight links on hover */
}

p, div {
  user-select: none; /* Disables text selection for these specific elements */
  -webkit-user-select: none;
  -moz-user-select: none;
}
