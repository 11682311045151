.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 65px;
  background-image: url('../../../../public/Grid.png'); /* Ensure image path is correct */
  background-position: top;
}

.side-panel {
  width: 70%; /* Full width on mobile */
  height: auto;

  box-sizing: border-box;
}

.section-container {
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  height: 100vh;
}
.section-containerr{
  scroll-snap-align: start;
  position: sticky;
  top: 100px;
}

.section-content {
 
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding-right: 50px;
  flex-grow: 1;
  width: 100%;

}


.active {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.menu-button {
  display: flex;
  justify-content: center;
 
  border-radius: 50px;
  width: 86%;
  border: solid 1px #FFFFFF;
  height: 50px;
  margin: 50px auto 0;
  opacity: 0.8;
}

.menu-button:hover{
  background-color: #4B4B50;

}



.menu-button h4 {
  color: #F8F8F8;
  font-family: Roboto;
  font-weight: 300;
  font-size: 23px;
  align-items: center;
  align-content: center;
  align-self: center;
}
.menu-button.selected {
  background-color: #4B4B50; /* Active background color */
  opacity: 1; /* Ensure full visibility for the active button */
}

.menu-button.selected h4 {
  color: #FFFFFF; /* Active text color */
 
}


.Interpretation-banner-er {
  height: 400px;
  background-image: url('.././../../../public/banner.png');
  margin-top: 105px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-position: center;
}

.Interpretation-banner-text-er {
  margin-left: 90px;
  font-size: 96px;
  color: white;
  text-align: flex-start;
  font-family: 'Ser Black', sans-serif;
}

.tag-h2 {
  cursor: pointer;
  margin-bottom: 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.tag-info-panel {
  width: 100%;
  max-height: 100%;
}

.tag-info-panel h3 {
  margin-bottom: 10px;
}

.tag-info-panel p {
  font-size: 16px;
  line-height: 1.6;
}

.section-content ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  max-height: 699px;
  font-size: 18px;
  list-style-type: none;
  padding: 0;
  /* margin-block-end: 2em; */
  background-color: #010243;
  border-radius: 50px;
  overflow-y: auto;
}

.section-content ul li {
  border-top-left-radius: 25px; 
  border-bottom-left-radius: 25px;
  margin-left: 20px;

  color: #F8F8F8;
  height: 89px;
  cursor: pointer;
  padding: 15px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.section-content ul li.hovered {
  color: #010243;
  background-color: #F8F8F8;
}

.section-content ul li.selected {
  color: #010243;
  background-color: #F8F8F8;
}

.tags-img {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.img-tag1,
.img-tag2 {
  width: 300px;
  height: 300px;
}

.toggle-button {
  padding: 10px;
  font-size: 16px;
  background-color: #010243;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 20px;
    flex-direction: column;
    margin-top: 100px;
  }
  
  .section-content ul li {
    height: 25px;
  }

  .side-panel {
    width: 100%;
    border-left: none;
    margin-bottom: 20px;
  }

  .section-content {
    padding-right: 0;
    width: 100%;
  }

  .section-content ul {
    width: 100%;
    font-size: 15px;
    padding-inline-start: 0;
  }
}

@media (max-width: 780px) {
  .Interpretation-banner,
  .Interpretation-banner-er {
    margin-top: 68px;
    height: 50vh;
    max-height: 100px;
    background-size: cover;
  }

  .Interpretation-banner-text,
  .Interpretation-banner-text-er {
    font-size: 32px;
    margin: 10px;
  }

  .menu-button {
    width: 60%;
    margin-top: 50px;
    margin-left: 45px;
  }

  .product-image {
    height: 150px;
  }
  .menu-button h4 {
    font-size: 13;
  }
  .section-containerr{
    position: static;
    top: 0;
  }

}
