
.Interpretation-section {
 
  background-color: #f9f9f9;
}

/* Banner section */
.Interpretation-banner {
  margin-top: 105px;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: url('../../../public/banner.png');
  background-position: center;
}

.Interpretation-banner-text {
  font-size: 86px;

  margin: 10px 90px;
  color: white;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 800;
}

@media screen and (max-width: 1600px){
  .Interpretation-banner-text {
    font-size: 82px;
  }
  
}

@media screen and (max-width: 1400px){
  .Interpretation-banner-text {
    font-size: 72px;
  }
  
}

/* Language interpretation section */
.Interpretation-language-section {
  padding: 80px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.Interpretation-language-section h1 {
  font-size: 42px;
  font-family: 'Roboto';
  color: #010243;
  font-weight: 700;
  margin-bottom: 20px;
}

.Interpretation-language-section p {
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #010243;
}

/* Types of interpretation section */
.Interpretation-types-section {
  padding: 80px;
  color: #f0f0f0;
  background-image: url('../../../public/background3.png'); /* Replace with the correct path to your image */
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

/* Optional overlay for better text readability */
.Interpretation-types-section::before {

}


.Interpretation-types-section h1 {
  font-size: 42px;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  margin-bottom: 20px;
}

.Interpretation-type-consecutive,
.Interpretation-type-escort,
.Interpretation-type-court {
  width: 70%;
  margin-bottom: 40px;
}
.Interpretation-type-escort svg{
  width: 100%;
  height: auto;
}
.Interpretation-type-consecutive svg{
  width: 100%;
  height: auto;
}

.Interpretation-type-consecutive h1,
.Interpretation-type-escort h1,
.Interpretation-type-court h1 {
  font-size: 28px;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
}

.Interpretation-type-consecutive p,
.Interpretation-type-escort p,
.Interpretation-type-court p {
  font-size: 18px;
  line-height: 1.6;
  color: #ffffff;
}

/* Translation section */
.Interpretation-translation-section {
  padding: 80px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.Interpretation-translation-section h1 {
  font-size: 32px;
  font-family: 'Roboto';
  color: #010243;
  margin-bottom: 20px;
}

.Interpretation-translation-section p {
  font-size: 18px;
  line-height: 1.6;
  color: #010243;
  margin-bottom: 15px;
}

/* Certified translation and immigration services section */
.Interpretation-certified-section {
padding: 80px;
 
  border-radius: 8px;
}

.Interpretation-certified-section h1 {
  font-size: 42px;
  font-family: 'Roboto';
  color: #010243;
  margin-bottom: 20px;
}

.Interpretation-certified-section p {
  
  font-family: 'Roboto';

  font-size: 18px;
  line-height: 1.6;
  color: #010243;
  margin-bottom: 15px;
}

/* Mobile responsiveness */
@media screen and (max-width: 908px) {
  .Interpretation-banner-text {
    font-size: 24px;

  }

  .Interpretation-language-section h1,
  .Interpretation-types-section h1,
  .Interpretation-translation-section h1,
  .Interpretation-certified-section h1 {
    font-size: 24px;
  }

  .Interpretation-language-section ,
  .Interpretation-types-section ,
  .Interpretation-translation-section ,
  .Interpretation-certified-section  {
    font-size: 24px;
    padding: 20px;
  }

  .Interpretation-type-consecutive h1,
  .Interpretation-type-escort h1,
  .Interpretation-type-court h1 {
    font-size: 20px;
  }
  .Interpretation-banner {
    margin-top: 68px;
    
  }

  .Interpretation-language-section p,
  .Interpretation-type-consecutive p,
  .Interpretation-type-escort p,
  .Interpretation-type-court p,
  .Interpretation-translation-section p,
  .Interpretation-certified-section p {
    padding: 0;
    font-size: 16px;
  }
}
