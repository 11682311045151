/* TagInfoPanel.css */

.tag-info-panel {
  width: 50%; /* Adjust the width as needed */
  padding: 20px;
  background-color: #f5f5f5;
  border-left: 1px solid #ddd;
  box-sizing: border-box;
  transition: transform 0.3s ease; /* Add transition for smooth hover effect */
}

.tag-info-panel:hover {
  transform: scale(1.05); /* Scale up on hover */
}

.tag-info-panel h3 {
  margin-bottom: 10px;
}

.tag-info-panel p {
  font-size: 16px;
  line-height: 1.6;
}
.product-cart-container {
  display: grid; /* Use grid or flexbox for layout */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive columns */
  gap: 16px; /* Spacing between product carts */
}

.product-cart-container-2 {
  justify-content: center;
  align-items: center;
  display: flex; /* Use grid or flexbox for layout */
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  gap: 50px; /* Spacing between product carts */
}
product-cart-container-2-1{
  display: flex;
  margin: 0 auto;
}

product-cart-container-2 p{
  font-size: 24px;
  font-family: "Roboto";
}
.product-cart-container-3 {
  display: flex; /* Use grid or flexbox for layout */
  flex-wrap: wrap;
  width: 100%;
  gap: 50px; /* Spacing between product carts */
}
product-cart-container-3 p{
  font-size: 24px;
  font-family: "Roboto";
}

product-cart-container-4 p{
  font-size: 24px;
  font-family: "Roboto";
}

.mis p {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 300;
}

.cms p {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 300;
}
.sib p {
  font-size: 24px;
  font-family: "Roboto";
}
.lvw p {
  font-size: 24px;
  font-family: "Roboto";
}
.product-cart-container-4 {
  display: flex; /* Use grid or flexbox for layout */
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
}
.default p{
  font-size: 24px;
  font-family: italic;
  font-family: "Roboto" ,"italic";
  font-weight: 300;
}

.product-cart-container-2-vps{
  display: flex; /* Use grid or flexbox for layout */
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  gap: 20px;
 
}

@media (max-width: 768px) {
  .product-cart-container-2 {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    
  }
  .product-cart-container-3{
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
  }
  .product-cart-container-2-vps{
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
  }

}