
/* components/ProductCart/ProductCart.css */
.product-cart {
  display: flex; /* Use flexbox for layout */
  justify-content: space-evenly; /* Space items to fill the container */

  text-align: center;
  min-height: 170px; /* Adjust width as needed */
  height: 100%; /* Ensure it fills the container */
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.product-description {
  margin-top: 12px; /* Add space above the description */
  font-size: 16px;
  color: #333; /* Adjust color as needed */
  /* Optional: add padding to the description if needed */
}
