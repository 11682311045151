.footer-container {
  display: flex;
  justify-content: center;
}

.footer {
  display: flex;
  justify-content:space-around;
   
  align-items: center;
  width: 100%; /* Adjust width to account for the padding */
  height: auto;
  max-height: 372px;
  background-color: #010243;
}

.footer-logo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 150px;
 
}

.footer-logo img {
  margin: 20px auto;
  max-width: 100%; /* Makes sure the image doesn’t overflow */
  height: auto; /* Keeps the image proportions */
}

.footer-our-services {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  text-align: flex-start;
  align-self: flex-start;
  color: #ffffff; /* Example text color */
  margin-left: 20px; /* Adds spacing between logo and sections */
}


.footer-contact-us{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  text-align: flex-start;
  align-self: flex-start;
  color: #ffffff; /* Example text color */
  margin-left: 20px; /* Adds spacing between logo and sections */

}
line-icon-s{
  width: 12px;
  height: 12px; /* Adjust the size of the icon */
}

.footer h4 {
  font-size: 1.5rem; /* Font size for the heading */
  color: #ffffff; /* Text color */
  margin-bottom: 15px; /* Space below the heading */
  display: flex; /* Flexbox for aligning items */
  align-items: center; /* Center items vertically */
}

.footer p, .footer ul {
  text-align: flex-start;
  margin: 5px 0; /* Adds spacing between paragraphs and list items */
  color: #ffffff; /* Ensures text is visible against the background */
}
.line-icon {
  margin-right: 8px; /* Space between icon and text */
  height: 24px; /* Aligns the image with the text */
}
.footer li {
  font-size: 1rem; /* Font size for list items */
  margin: 10px 0; /* Space between list items */
  display: flex; /* Flexbox for aligning items */
  align-items: center; /* Center items vertically */
  color: #51A8DB
}
.footer-contact-us p{
  color: #51A8DB;
  align-self: flex-start;
}
.footer-contact-us h4{

  align-self: flex-start;
}
.footer-our-services ul img{
  width: 24px;
  height: 24px; /* Adjust the size of the icon */
}

/* Mobile Styles */
@media (max-width: 800px) {
  .footer {
    width: 100%;
    font-size: 8px;
    flex-direction: row; /* Stack elements vertically */
    padding: 0; /* Adjust padding for smaller screens */
    align-items: center; /* Center items */
  }

  .footer-logo {
    width: 100%; /* Make logo full width */
    text-align: center; /* Center logo */
  }
  .footer h4 {
    font-size: 0.5rem; /* Font size for the heading */
    color: #ffffff; /* Text color */
    margin-bottom: 15px; /* Space below the heading */
    display: flex; /* Flexbox for aligning items */
    align-items: center; /* Center items vertically */
  }

  .footer-our-services {
    margin: 10px;
    margin-left: 0;
    width: 100%; /* Make sections full width */
    margin-bottom: 20px; /* Add space below sections */
    order: 3;
  }

  .footer-contact-us{
    width: 100%; /* Make sections full width */
    margin-bottom: 20px; /* Add space below sections */

  }
  .line-icon {
    margin: 1px;
    margin-right: 8px; /* Space between icon and text */
    height: 10px; /* Aligns the image with the text */
  }

  .footer li {
    font-size: 0.5rem; /* Font size for list items */
    margin: 10px 0; /* Space between list items */
    display: flex; /* Flexbox for aligning items */
    align-items: center; /* Center items vertically */
    color: #51A8DB
  }
  .footer-contact-us p {
    font-size: 8px;
    color: #51A8DB; /* Change list item color for mobile */
  }
  line-icon-s{
    width: 10px;
    height: 9px; /* Adjust the size of the icon */
  }
}
