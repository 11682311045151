.main-header {

  position: fixed !important; /* Ensures the header is fixed */
  top: 0; /* Ensures it stays at the top of the viewport */
  left: 0; /* Ensures it starts from the left edge */
  width: calc(100% - 200px); /* Subtracts 100px from both sides */
  max-height: 118px; /* Sets the maximum height */
  display: flex;
  justify-content: space-between; /* Aligns child elements */
  align-items: center; /* Centers items vertically */
  padding: 0 100px; /* Padding of 0 top and bottom, 100px left and right */
  background-color: #DDDDE2; /* Background color */
  color: #fff; /* Text color */
  z-index: 9999; /* Ensures it stays on top of other elements */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
}

.logo-container {
  /* Add any styling for the logo container */
}

.logo {
  width: 296px; /* Adjust the width of the logo for larger screens */
  height: 63px; /* Maintain aspect ratio */
  color: #fff; /* Change logo text color */
}

.main-navigation {
  display: flex;
  align-items: center;
}

.main-navigation ul {
  list-style: none;
  display: flex;
}

.main-navigation li {
  margin-right: 20px; 
  cursor: pointer; /* Change cursor style */
}

.main-navigation a {
  font-size: 24px;
  font-weight: 400;
  font-style: 'Robot';
  text-decoration: none;
  color: #0B0B0B; /* Change the text color as needed */
}

@media (max-width: 1470px) {

  .main-navigation a {
    font-size: 20px;
    
  }
}
@media (max-width: 1200px) {

  .main-navigation a {
    font-size: 18px;
    
  }
}
@media (max-width: 800px) {

  .main-navigation a {
    font-size: 16px;
    
  }
}

.main-navigation a:hover {
  color: #51A8DB;
  
}

/* Dropdown styles (if needed) */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333; /* Change the background color as needed */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #fff; /* Change the text color as needed */
  padding: 12px 16px;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Mobile Menu Icon */
.menu-icon {
  display: none; /* Hide menu icon by default for larger screens */
  cursor: pointer;
  margin-right: 50px; /* Space between menu icon and logo */
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff; /* Color of the bars */
  margin: 6px 0;
  transition: 0.4s;
}

@media (max-width: 885px) {

  
  .main-header {
    width: 100%;
    padding: 0; /* Add padding of 10px left and right for mobile */
  }
  .menu-icon {
    display: block; /* Display menu icon for smaller screens */
  }

  .main-navigation ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    text-align: center;
    position: absolute;
    top: 50px; /* Adjust the top spacing as needed */
    left: 0;
    background-color: #DDDDE2; /* Change the background color as needed */
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;
    padding: 0; /* Remove padding for the ul */
  }

  .main-header.mobile-menu-open {
    background-color: #DDDDE2; /* Change the background color when the mobile menu is open */
    /* Add any additional styles for the main header when the mobile menu is open */
  }
  
  .main-header.mobile-menu-open .main-navigation ul {
    opacity: 1;
    visibility: visible;
  }

  .main-navigation li {
    margin: 10px 0; /* Space between items on mobile */
    cursor: pointer; /* Change cursor style */
  }

  /* Adjust logo size for mobile */
  .logo {
   margin-left: 10px;
    width: 110px; 
    height: 25px;
  }
}

/* Remove default list styling */
li {
  list-style: none; 
}
