.home-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #F8F8F8;
    
}
.home-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
    /* Full viewport height */
    
    max-width: 1920px;
}

.section-1 {
  width: 100%;
  margin-top: 100px;
    height: 897px; /* Full viewport height for the section */
    background-image: url('../../../public/Home.png'); /* Update with your image path */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    display: flex; /* Use Flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    color: #fff; /* Text color */
    text-align: center; /* Center text */
    position: relative; /* Position relative for the pseudo-element */
    overflow: hidden; /* Hide overflow to prevent showing the curve outside */
}

.info-section {
    align-self: flex-start; /* Align section to the left */
    border-radius: 10px; /* Rounded corners for aesthetics */
    text-align: left; /* Align text to the left */
    margin-top: -150px; /* Raise the section above its normal position */
    padding-left: 100px; /* Reduced padding inside the section */
}

.info-section h1 {
    font-family: 'Roboto', ExtraBold;
    font-size: 4rem; /* Large font size for main title */
    font-weight: 800; /* Bold font weight */
    color: #FFFFFF; /* Heading color */
    line-height: 1rem;
  }
  
  .info-section h2 {
    font-family: 'Roboto', ExtraBold; /* Apply Roboto font */
    font-style: italic; /* Italic style */
    font-weight: 200; /* Light font weight */
    font-size: 4rem; /* Font size for subtitle */
    margin-bottom: 20px; /* Space below heading */
    color: #FFFFFF; /* Subtitle color */
    line-height: 2rem;
    opacity: 0.8;
  }
  
  .info-section p {
    font-family: 'Roboto';
    font-style: Light;
    font-size: 1.5rem; /* Text size for paragraph */
    font-weight: 300; /* Normal font weight */
    margin-bottom: 30px; /* Space below paragraph */
    color: #F8F8F8;
    opacity: 0.8;
    ; /* Lighter text color for description */
  }

/* Search Section Styles */
.search-section {
    display: flex; 
    
}
.search-input::placeholder {
  color: rgb(255, 255, 255); /* Replace 'gray' with your desired color */
}

.search-input {
  width: 100%;
  background-color: unset;
    padding: 10px; /* Padding inside the input */
    border-radius: 50px; /* Rounded corners */
    margin-right: 400px; /* Space between input and button */
    font-size: 1rem; /* Font size for input */
    
}
.search-icon {
  position: absolute;
  right: -30px;
  top: 39%;
  transform: translateY(-50%);
  width: 20px; /* Adjust icon size as needed */
  height: 20px;
  pointer-events: none; /* Allows clicks to pass through the icon */
}

.search-button {
    padding: 10px 20px; /* Padding inside the button */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    background-color: #007bff; /* Button background color */
    color: white; /* Button text color */
    cursor: pointer; /* Change cursor on hover */
    font-size: 1rem; /* Font size for button */
    transition: background-color 0.3s; /* Transition for background color */
}

.search-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.section-2 {
  height: 431px;
    padding: 20px; /* Padding for the new section */
    color: #000000; /* Text color for contrast */
    display: flex; /* Use Flexbox for layout */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
}

/* Services Section Styles */
.text-section {
    background-image: url('../../../public/Mask.png'); /* Update with your image path */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    padding: 20px; /* Padding inside the section */
    text-align: center; /* Center align text for aesthetics */
    margin: 139px; /* Space around the section */
}

.text-section p:first-of-type {
    font-family: 'Roboto', sans-serif; /* Apply Roboto font */
    font-size: 50px; /* Set font size */
    font-weight: 300; /* Set font weight */
    text-align: center; /* Center align text */
    opacity: 0.9; /* Slight transparency for the text */
}

.text-section p:last-of-type {
    font-family: 'Roboto'; /* Apply Roboto font */
    font-size: 18px; /* Set font size */
    font-style: italic; /* Set font style to italic */
    font-weight: 200; /* Set font weight */
    line-height: 1.76rem; /* Set line height */
    text-align: left; /* Align text to the left */
}

/* Highlight text for interpretation/translation */
.highlight-text {
    color: #51A8DB;
    font-weight: 300; /* Set font weight */
}

/* Highlight background for equipment rental */
.highlight-background {
    color: #3361E1; /* Optional: Set text color for contrast */
    padding: 2px 4px; /* Optional: Add some padding for better appearance */
    border-radius: 3px; /* Optional: Add rounded corners */
}


 

 .section-3{
  width: 100%;
  color: #000000; /* Text color for contrast */
  display: flex; /* Use Flexbox for layout */
  justify-content: center; /* Center content horizontally */
  align-items: center;
  flex-direction: column;
 }

 .services-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart-section{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-end;
  gap: 50px;
  margin-left: 40px;
}

.services-title {
  font-size: 50px;
  font-weight: 400;
  margin-bottom: 20px;
  background: linear-gradient(91.16deg, #010243 1.74%, #51A8DB 43.71%, #51A8DB 61.62%, #010243 102.48%);
  background-clip: text;
  -webkit-background-clip: text; /* Ensures compatibility with WebKit-based browsers */
  color: transparent; /* Makes the gradient visible by making the text color transparent */
}

.cart-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 400px;
}

.cart-1,
.cart-2 {
  
  width: 50%; /* Each cart takes 48% with a little gap */
}


.cart-2 img {
  width: 90%;
}
.cart-1 img {
  width: 90%;
}


 .container-1{
  display: flex;
  flex-direction: column;
  align-self:baseline;
  
  background-image: url('../../../public/Vector2.svg'); /* Update with your image path */
  background-size:auto; 
  background-repeat: no-repeat;
  width: 100%;  /* Full viewport width */
  height: 730px;
  
  background-position: center; /* Center the image */
  justify-content: center; /* Center content horizontally */
  align-items: center;  
 }

 .our-product-1{
  display: flex;
  flex-direction: row;
  
    width: 80%;
    height: 30%;
   
    max-width: 1300px;
 }
 .our-product-text{
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
 }
 .our-product-text-1{
  display: flex;
  
  flex-direction: column;
  align-self: center;
  width: 50%;
  height: 50px;
  align-items: center;
 }
 .our-product-text-1-mobile{
  display: none;
 }
 .our-product-text-1 h1{
  align-self: flex-start;
  font-size: 50px;
  font-weight: 400;
  margin-bottom: 20px;
  background: linear-gradient(91.16deg, #010243 1.74%, #51A8DB 43.71%, #51A8DB 61.62%, #010243 102.48%);
  background-clip: text;
  -webkit-background-clip: text; /* Ensures compatibility with WebKit-based browsers */
  color: transparent;
 }
 .our-product-text-1 p{
  align-self: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 400;
 }



  .our-service {
    
    display: flex;
    flex-direction: column;
    background-image: url('../../../public/Vector.png'); /* Update with your image path */
    background-size:auto; 
    background-repeat: no-repeat;
    width: 100%;  /* Full viewport width */
    height: 100%;
    padding: 40px;
    background-position: center; /* Center the image */
    justify-content: center; /* Center content horizontally */
    align-items: center;     /* Center content vertically */
  }
  
 

 
 
  
 
  
 

  .our-product-button-section a{
   
    display: flex;
    height: 90%;
    justify-content: center;
    flex-direction: row;
  }
  .our-product-button-section a button{
    display: flex;
    flex-direction: row;
    color: #F8F8F8;
    border-radius: 24px;
    background-color: #000000;
   align-self: flex-end;
    width: 192px;
    height: 48px;
  }
  .our-product-button-section a p{
    color: #f4f4f4;
  }

  .our-producte-cart-section {
   
    display: flex;
    justify-content: flex-start;
flex-direction: row;
flex-wrap: wrap;
    width: 60%;
    height: 700px;

    position: relative; /* or absolute depending on your needs */
  left: 20px; /* Adjust this to control the left positioning */
  top: -80px;  }
  
  .card-container-1,.card-container-2 {
    display: flex;
    
    flex-direction: row;
flex-wrap: nowrap;
    gap: 100px;
    justify-content: space-between; /* Spacing between cards */
  }
  .card-container-1-mobile, .card-container-2-mobile {
    display: none;
  }
  
  .card {
    border-radius: 25px;
    width: 510px; /* Exact width as per your specification */
    height: 201px; /* Exact height as per your specification */
    background-color: #f4f4f4;
    opacity: 1; /* Initially visible */
  }
  .card img {

    width: 100%; /* Makes the image fill the width of its container */
    height: auto; /* Keeps the aspect ratio of the image */
    object-fit: cover; /* Ensures the image covers the container while keeping its aspect ratio */
    border-radius: 25px; /* Optional: Adds rounded corners */
    transition: transform 0.3s ease-in-out; /* Adds a smooth zoom effect on hover */
  }
  
  .card img:hover {
    transform: scale(1.05); /* Slightly enlarges the image when hovered */
  }
  .card p {
    
    font-size: 16px; /* Adjust the size of the text */
    color: #333; /* Text color */
    text-align: start; /* Centers the text */
    margin-top: 10px; /* Adds some space between the image and text */
  }

  .contact-baner {
    width: 100%;
    overflow: hidden;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #D7D7D766;
    
    border-width: 1px 0px 1px 0px; /* Corrected: Use spaces instead of commas */
    border-style: solid;
    border-color: #010243;
  
    margin-bottom: 100px;
  }

  .contact-button {
    color: white; /* White text */
    padding: 10px 20px; /* Adds space inside the button */
    font-size: 16px; /* Adjusts text size for readability */
    font-family: 'Roboto', sans-serif; /* Consistent font style */
    border: none; /* Removes default borders */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Changes the cursor to a pointer on hover */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds subtle shadow for depth */
    display: inline-flex; /* Allows flexbox centering */
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
    text-align: center; /* Ensure text is centered */
  }
  
  .contact-button:hover {
    background-color: #000000; /* Darker shade on hover */
    transform: translateY(-2px); /* Lifts the button slightly */
  }
  
  .contact-button:active {
    background-color: #010243; /* Even darker on click */
    transform: translateY(0); /* Removes lift on click */
  }
  
  .contact-button:focus {
    outline: 2px solid #80bdff; /* Adds focus outline for accessibility */
    outline-offset: 2px; /* Separates the outline from the button */
  }

  
  

  .contact-baner-text{
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 100%;

  }
  .imgbutton  {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    padding-left: 100px;

   
  }
  .imgbutton  h1{
    align-self: flex-start;
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 20px;
    background: linear-gradient(91.16deg, #010243 1.74%, #51A8DB 43.71%, #51A8DB 61.62%, #010243 102.48%);
    background-clip: text;
    -webkit-background-clip: text; /* Ensures compatibility with WebKit-based browsers */
    color: transparent;

  }
  .imgbutton a{
    display: flex;
    justify-content: flex-end;
  }

  .t-b{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 82px;
  }

  .t-b p{
    width: 50%;
    align-self: flex-start;
    margin: 20px;
    font-family: Roboto;
    font-weight: 400;
    justify-content: flex-start;
    font-size: 16px;
 
  }
  .imgbutton a button{
    margin-top: 15px;
 align-self: flex-end;
 display: flex;
    flex-direction: row;
    color: #F8F8F8;
    border-radius: 24px;
    background-color: #000000;
    
    width: 192px;
    height: 48px;
  }



  
  .contact-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #010243; /* Customize as needed */
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    gap: 8px; /* Adds space between text and image */
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .contact-button p {
    align-self: center;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  
  .contact-button img {
    width: 20px; /* Adjust size as needed */
    height: auto;
  }
  
  .contact-button:hover {
    background-color: #005bb5; /* Darken on hover */
  }
  
  .imgbutton a {
    text-decoration: none;
  }
  
 
  
  
  @media (max-width: 768px) {

    .section-1 {
      background-image: url('../../../public/bgmobile.png'); /* Update with your image path */

      margin-top: 65px;
    }
    
   
    .imgbutton  {
     justify-content: first baseline;
      flex-direction: column;
      padding-left: 10px;
      width: 90%;
 
  
     
    }
    .our-product-1{
      margin-top: 50px;
    }
    .our-product-text-1{
      display: none;
    }
    .our-product-text-1-mobile{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      top: -31px;
      left: 10px;
      width: 182px;
      height: 100px;
     
    }
    .our-product-text-1-mobile b{
      font-size: 26px;
      line-height: 2rem;
      align-self: flex-start;
     
      font-weight: 400;
 
      background: linear-gradient(91.16deg, #010243 1.74%, #51A8DB 43.71%, #51A8DB 61.62%, #010243 102.48%);
      background-clip: text;
      -webkit-background-clip: text; /* Ensures compatibility with WebKit-based browsers */
      color: transparent;
      
     
    }
    .our-product-text-1-mobile p{
      font-size: 11px;
      line-height: 1rem;
      
      
     
    }
     .our-product-button-section
     {
      width: 94px;
      height: 24px;
      border-radius: 15px;
     }
     .our-product-button-section a p
     {
      align-self: center;
    font-size: 8px;
     }

    .our-producte-cart-section {
      
     
      display: flex;
      justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
      width: 80%;
      height: 600px;
      background-color: none;
      position: relative; /* or absolute depending on your needs */
    left: 20px; /* Adjust this to control the left positioning */
    top: -80px;
    overflow: hidden;
    }
    .card {
      width: 307px; /* Exact width as per your specification */
      height: 201px; /* Exact height as per your specification */
      background-color: none;
      border-radius: 24px 0px 0px 0px; /* Custom border-radius */
      opacity: 1; /* Initially visible */
    }
    .card-container-1{
      display: none;

    }
    .card-container-1-mobile, .card-container-2-mobile {
      display: flex;
      flex-direction: row;
  flex-wrap: wrap;
      gap: 50px;
      justify-content: space-between; /* Spacing between cards */
    }
    .card-container-2{
      display: none;
    }
    .card p{
      font-size: 8px;
    }
  }
  
  
  

/* Responsive Styles */
@media (max-width: 600px) {
  .section-1{
    max-height: 627px;
padding: 0;
  }
  .section-2{
    height: 200px;
  }
 


    .services-section {
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: center; /* Center items */
        height: 194px;
    }

    .cart-1 {
        max-width: 90%; /* Max width for smaller screens */
    }
}



/* Mobile Styles */
@media (max-width: 885px) {
  
    .info-section {
        width: 90%; /* Increase width for mobile devices */
        max-width: 90%; /* Ensure text does not exceed 90% of the container width */
        padding: 10px; /* Adjust padding for mobile */
    }

    .info-section h1 {
        font-size: 26px; /* Large font size for main title */
        font-weight: 800; /* Bold font weight */
        color: #FFFFFF; /* Heading color */
        line-height: 1rem;
      }
      
      .info-section h2 {
        font-family: 'Roboto', extralight italic; /* Apply Roboto font */
        font-style: italic; /* Italic style */
        font-weight: 200; /* Light font weight */
        font-size: 26px; /* Font size for subtitle */
        margin-bottom: 20px; /* Space below heading */
        color: #FFFFFF; /* Subtitle color */
        line-height: 2rem;
        opacity: 0.8;
      }
      
      .info-section p {
        font-size: 26px; /* Text size for paragraph */
        font-weight: 300; /* Normal font weight */
        margin-bottom: 30px; /* Space below paragraph */
        color: #F8F8F8;
        opacity: 0.8;
        ; /* Lighter text color for description */
      }

    .info-section p:first-of-type {
        font-size: 1.5rem; /* Decrease font size for the first paragraph on mobile */
    }

    .info-section p:last-of-type {
        font-size: 0.625rem; /* Adjust font size for the second paragraph on mobile */
    }

    .search-input {
      margin-right: 0;
        font-size: 0.9rem; /* Adjust font size for the search input on mobile */
    }

    .search-button {
        font-size: 0.9rem; /* Adjust font size for the search button on mobile */
    }
    .text-section{
      margin: 0;
    }

    .services-section p:first-of-type {
        font-size: 2rem; /* Adjust font size for services on mobile */
        line-height: 2.25rem; /* Adjust line height for services on mobile */
    }

    .services-section p:last-of-type {
        display: none;
    }

    .text-section p:first-of-type{
        font-size: 1.3rem;
        line-height: 25px;
    }
    .text-section p:last-of-type{
        display: none;
    }

    .container-1 {
        display: flex;
        background-image: url('../../../public/VectorMobile.png'); /* Update with your image path */
        height: 290px;
      }
      .our-product-button-section a button{
        width: 100%;
      }

      .imgbutton h1{
        font-size: 26px;
      }

      .imgbutton a button{
        width: 150px;
      }
      .t-b  p{
        padding-left: 0;
        font-size: 12px;
        
      }
      .t-b  {
        padding-left: 0;
        font-size: 12px;
        
      }

      .our-product-text-1 p{
        font-size: 16px;
      }
      
}
