/* ClientCart.css */

/* Container styling for the entire client cart section (parent container for all cards) */
.client-cart-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping onto multiple rows */
  justify-content: center; /* Centers the items in the container */
  gap: 20px; /* Adds space between the cards */
  padding: 20px;
}

/* Container styling for each client cart */
.client-cart-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
 
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: calc(25% - 40px); /* Adjust width to allow 4 cards in a row (minus the gap) */
  max-width: 250px; /* Maximum width for the card */

  margin: 10px;
}

.client-cart-1:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

/* Styling for the client image */
.client-image-1 {
  height: auto;
  border-radius: 6px;
  margin-bottom: 15px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.client-cart-1:hover .client-image-1 {
  transform: scale(1.05);
}

/* Client description text styling with width constraint matching image */
.client-description-1 {
  font-size: 20px;
  color: #333;
  text-align: center;
  font-weight: 300;
  padding: 10px 0; /* Padding for the paragraph */
  max-width: 100%; /* Matches the width of the image */
  width: 100%; /* Ensures the text does not exceed image size */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  transition: color 0.3s ease;
}

.client-cart-1:hover .client-description-1 {
  color: #555; /* Slight change in color on hover */
}

/* Mobile responsiveness */
@media (max-width: 1024px) {
  .client-cart-1 {
    width: calc(33.333% - 40px); /* 3 cards per row on medium screens */
  }
}

@media (max-width: 768px) {
  .client-cart-1 {
    width: calc(50% - 40px); /* 2 cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .client-cart-1 {
    width: calc(100% - 40px); /* 1 card per row on extra-small screens */
  }
  
  .client-description-1 {
    font-size: 14px;
  }
}
